import styled from 'styled-components/macro';

const Card = ({className, children}) => <div className={className}>{children}</div>;

export default styled(Card)`
  width: 100%;
  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
  border-radius: 15px;
  padding: 20px;
  background-color: ${props => props.disabled ? '#ccc' : '#fff'} ;
`;

