import { useRouter } from 'next/router';
import React from 'react';
import { Tabs, TabList, Tab, TabPanel, resetIdCounter } from 'react-tabs';
import { useSetRecoilState } from 'recoil';
import { IoReloadCircleOutline } from "react-icons/io5";

import { tabIndexAtom } from '../core/components/atoms';
import FilterContainer from 'components/filterContainer';
import OrdersPicking from 'core/containers/OrdersPickinig';
import OrdersPacking from 'core/containers/OrdersPacking';
import OrdersSyncing from 'core/containers/OrdersSyncing';
import OrdersShipped from 'core/containers/OrdersShipped';
import OrdersError from 'core/containers/OrdersError';
import DefaultLayout from 'core/layouts/DefaultLayout';
import WithPrivateRoute from 'components/withPrivateRoute';
import { useOrderUnlock } from 'core/components/useOrderLock';
import { useSelectedOrderData } from 'core/components/SelectedOrderData';
import { useTitleCounter } from 'core/components/TabTitleCounter';

const pickingStatuses = ['processing']
const packingStatuses = ['picked']
const syncingStatuses = ['shipping', 'packed']
const errorStatuses = ['hold', 'failed', 'pending']
const shippedStatuses = ['shipped', 'delivered']

const Home = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const router = useRouter();
  const [filterModal, setFilterModal] = React.useState(false);
  useSelectedOrderData();
  useOrderUnlock();
  const storeIndexTab = useSetRecoilState(tabIndexAtom);
  const [titleKey, setTitleKey] = React.useState(Date.now())

  React.useEffect(() => {
    const { tab } = router.query;

    switch (tab) {
      case 'packing':
        setTabIndex(1);
        break;
      case 'syncing':
        setTabIndex(2);
        break;
      case 'error':
        setTabIndex(3);
        break;
      case 'shipped':
        setTabIndex(4);
        break;
      case 'picking':
      default:
        setTabIndex(0);
    }
  }, [router.query]);

  React.useEffect(() => {
    storeIndexTab(tabIndex)
  }, [storeIndexTab, tabIndex]);

  React.useEffect(() => {
    if (filterModal) {
      document.getElementsByTagName('BODY')[0].classList.add('disable-scroll');
    } else {
      document.getElementsByTagName('BODY')[0].classList.remove('disable-scroll');
    }
  }, [filterModal]);

  React.useEffect(() => {
    resetIdCounter();
  }, []);

  const handleReload = (e) => {
    e.stopPropagation()
    setTitleKey(Date.now())
  }

  return (
    <DefaultLayout noPadding header={{ title: 'Order Feed', overrideTitle: true }}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={index => setTabIndex(index)}
        className={{ disabled: true }}
      >
        <div className="overflow-scroll">
          <TabList className="tabs-control">
            <Tab className="tabs-control-btn" id="picking">
              <TabTitle key={`picking_${titleKey}`} statuses={pickingStatuses}>Picking</TabTitle>
            </Tab>
            <Tab className="tabs-control-btn" id="packing">
              <TabTitle key={`picking_${titleKey}`} statuses={packingStatuses}>Packing</TabTitle>
            </Tab>
            <Tab className="tabs-control-btn" id="syncing">
              <TabTitle key={`picking_${titleKey}`} statuses={syncingStatuses}>Syncing</TabTitle>
            </Tab>
            <Tab className="tabs-control-btn" id="error">
              <TabTitle key={`picking_${titleKey}`} statuses={errorStatuses}>Error</TabTitle>
            </Tab>
            <Tab className="tabs-control-btn" id="shipped">
              <TabTitle key={`picking_${titleKey}`} statuses={shippedStatuses}>Shipped</TabTitle>
            </Tab>
            <Tab className="tabs-control-btn" id="reload" style={{ position: 'relative' }}>
              <button onClick={handleReload} style={{ background: 'white', borderRadius: '50%', width: 32, height: 32, display: 'flex', padding: 0, border: 0, fontSize: 32, color: 'black', position: 'absolute', top: 0, right: 16 }}><IoReloadCircleOutline /></button>
            </Tab>
          </TabList>
        </div>

        <FilterContainer setFilterModal={setFilterModal} filterModal={filterModal} tabIndex={tabIndex} />

        <TabPanel className="select-tabs-screen">
          <OrdersPicking />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersPacking />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersSyncing />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersError />
        </TabPanel>
        <TabPanel className="select-tabs-screen">
          <OrdersShipped />
        </TabPanel>
        <TabPanel />
      </Tabs>
    </DefaultLayout>
  );
};

export default WithPrivateRoute(Home);

const TabTitle = React.memo(function TabTitle(props) {
  const { children, statuses } = props
  const counter = useTitleCounter(statuses)

  if (counter) {
    return <div>{children} ({counter})</div>
  }

  return <div style={{ marginRight: 27 }}>{children}</div>
})

// function ErrorTabTitle() {
//   const errors = useRecoilValue(errorsCounterAtom)

//   if (errors) {
//     return `Error (${errors})`
//   }

//   return "Error"
// }
