import styled from 'styled-components/macro';

const FiltersButton = ({...props}) => <button {...props} />;

export default styled(FiltersButton)`
  cursor: pointer;
  background: #fff;
  -webkit-box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
  box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 0 12px;
  z-index: 1;
  -webkit-transition: top 0.5s;
  transition: top 0.5s;
  border: none;
  outline: none;
  font-size: 12px;
  height: 32px;
`;
