import styled from 'styled-components/macro';
import React from 'react';
import ListItemPrintButton from '../ListItemPrintButton';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import QUEY_ORDER from '../../graphql/queries/GET_ORDER'

const MUTATION_SHIP_AGAIN = gql`
  mutation shipAgain($id: ID!) {
    shipAgain(id: $id) {
      id
    }
  }
`;

const ButtonPrintOrderLabels = ({ className, order }) => {
  const { id } = order;
  const [fetching, setFetching] = React.useState(false);
  const [shipAgain] = useMutation(MUTATION_SHIP_AGAIN, { variables: { id }, fetchPolicy: 'network-only' });
  const [getOrder, { loading }] = useLazyQuery(QUEY_ORDER, { variables: { id }, fetchPolicy: 'network-only' })
  const timer = React.useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    setFetching(true);
    shipAgain();
    timer.current = setTimeout(() => {
      getOrder();
      setFetching(false);
    }, 10 * 1000);
  }

  return <ListItemPrintButton className={className} onClick={handleClick} loading={fetching || loading} />;
};

export default styled(ButtonPrintOrderLabels)``;
