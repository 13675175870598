import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { LIST_ORDERS } from 'core/graphql/queries';
import useOrderFilters from 'hooks/orders/useOrderFilters';
import useSettings from 'hooks/useSettings';
// import { useDebounce } from 'react-use';

const perPage = 20;

export default function useListOrdersQuery({ status, statuses, sort: initialSort, withoutAutoupdate }) {
  const { filters } = useOrderFilters();
  const [settings] = useSettings();
  const { date, zone, defaultCarrier, sort: sortFilters } = filters || {};
  const [refetching, setRefetching] = React.useState(false);

  const dateFilters = React.useMemo(() => {
    if (date?.filter(Boolean).length) {
      return { from: extractDate(date[0]), to: extractDate(date[1]) };
    }
    return {};
  }, [date]);

  const statusFilter = useMemo(() => {
    if (status) {
      return { status };
    }

    if (statuses) {
      return { statuses };
    }
  }, [status, statuses]);

  const filter = useMemo(() => {
    return {
      ...statusFilter,
      ...dateFilters,
      defaultCarrier,
      zone,
      withoutQa: !settings?.qa
    };
  }, [statusFilter, dateFilters, zone, defaultCarrier, settings]);

  const sort = useMemo(() => {
    const selectedSort = Object.entries(sortFilters ?? {})[0];

    if (!selectedSort || !selectedSort[1]) {
      return initialSort || ["defaultCarrier", 'asc']
    }
    return selectedSort;
  }, [sortFilters, initialSort]);

  const [getListOrders, { data, fetchMore, loading, refetch, ...other }] = useLazyQuery(
    LIST_ORDERS,
    {
      variables: { data: { filter, sort, range: [0, perPage] } }
    }
  );

  const loadMore = () => {
    fetchMore({
      variables: {
        data: {
          filter,
          sort,
          range: [
            data?.listOrdersExt?.range[0] + perPage,
            data?.listOrdersExt?.range[1] + perPage
          ]
        }
      }
    });
  };

  const orders = useMemo(() => data?.listOrdersExt?.orders, [data]);
  const total = data?.listOrdersExt?.total || 0

  const isLoading = useMemo(() => {
    return loading && !orders?.length;
  }, [loading, orders]);

  useEffect(() => {
    if (dateFilters.from && dateFilters.to) {
      getListOrders();
    }
  }, [dateFilters, getListOrders]);

  const loadAll = async () => {
    const pages = Math.ceil(total / perPage);
    for (let page = 0; page < pages; page++) {
      await fetchMore({ variables: { data: { filter, sort, range: [page * perPage, (page + 1) * perPage] } } });
    }
  }

  useEffect(() => {
    if (refetching || !orders?.length || withoutAutoupdate) {
      return;
    }
    const timer = setInterval(async () => {
      setRefetching(true);
      const pages = Math.ceil(orders.length / perPage);
      for (let page = 0; page < pages; page++) {
        await fetchMore({ variables: { data: { filter, sort, range: [page * perPage, (page + 1) * perPage] } } });
      }
      setRefetching(false);
    }, 10000);
    return () => {
      clearInterval(timer);
    }
  }, [fetchMore, filter, orders?.length, refetching, sort, withoutAutoupdate]);

  // useEffect(() => {
  //   const timer = setInterval(() => { refetch() }, 10000);
  //   return () => {
  //     clearInterval(timer);
  //   }
  // }, [refetch]);

  // useDebounce(() => {
  //   if (syncOrderSubscriptionData) {
  //     refetch();
  //   }
  // }, 5000, [refetch, syncOrderSubscriptionData]);

  return {
    data: orders,
    loading: isLoading,
    total,
    hasNextPage: data?.listOrdersExt?.total > data?.listOrdersExt?.range[1],
    loadMore,
    refetch,
    getListOrders,
    loadAll,
    ...other
  };
}

function extractDate(dt) {
  try {
    const d = new Date(dt);
    // return d.toISOString().split('T')[0];
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${year}-${month}-${day}`;
  } catch (ex) {
    return dt;
  }
}
