import styled from 'styled-components/macro';
import usePrint from 'core/hooks/usePrint';
import {useMemo} from 'react';
import ListItemPrintButton from '../ListItemPrintButton';
import easyPostCarriers from 'core/lib/easyPostCarriers';

const EasyPostPrintTemplateMap = {
  UPS: 'UPS-Shipping Label',
  USPS: 'USPS-Shipping Label'
};

const ButtonPrintOrderLabels = ({className, order}) => {
  const {items} = order;
  const {sendToPrint: sendToPrintOrder, loading} = usePrint();

  const boxes = useMemo(() => items?.filter(item => item?.product?.isBox), [items]);

  const printPagesCount = useMemo(() => boxes?.length, [boxes]);

  const print = e => {
    e.preventDefault();

    const variables = {
      content: JSON.stringify(order),
      type: 'ship',
      id: order.id,
      pages: printPagesCount,
      orderId: order?.id,
      customerName: order?.customer
        ? `${order?.customer?.firstName} ${order?.customer?.lastName}`
        : 'undefined'
    };

    if (easyPostCarriers.includes(order.carrier)) {
      sendToPrintOrder({
        ...variables,
        template: 'EasyPostLabels',
        printTemplate: EasyPostPrintTemplateMap[order?.carrier]
      });
    } else {
      sendToPrintOrder({
        ...variables,
        template: 'Route4meLabels',
        printTemplate: 'Local Delivery-Shipping Label'
      });
    }
  };

  return <ListItemPrintButton className={className} onClick={print} loading={loading} />;
};

export default styled(ButtonPrintOrderLabels)``;
