import styled from 'styled-components/macro';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
`;

export const Counter = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
`;

export const OrderInfo = styled.div`
  display: flex;
  gap: 15px;
`;

export const ShippingInfo = styled.div`
  font-size: 15px;
  text-align: right;
  margin-left: auto;
`;

export const Footer = styled.div``;

export const Customer = styled.div``;

export const Zone = styled.div``;

export const ShipBy = styled.div`
  align-self: center;
  color: red;
`;

export const Status = styled.span`
  font-size: 18px;
  font-weight: normal;
`;
export const InfoLeftPart = styled.div``;

export const Printed = styled.div`
  color: #0A6629;
  margin: 0 5px;
  font-weight: bold;
`
