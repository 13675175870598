import useAppStore from 'hooks/useAppStore';
import { useEffect } from 'react';
import FiltersStorage from 'services/FiltersStorage';
import getDayDateRange from 'utils/getDayDateRange';

export default function useOrderFilters(initState) {
  const { filters, setFilters } = useAppStore();

  useEffect(() => {
    const storedFilters = FiltersStorage.getFilters();

    if (storedFilters === null) {
      FiltersStorage.setFilters(initState);
      setFilters(initState);
    } else {
      setFilters(storedFilters);
    }
  }, []); // eslint-disable-line

  const handleSetFilters = filters => {
    setFilters(currentFilters => {
      const resultFilters = { ...currentFilters, ...filters };
      FiltersStorage.setFilters(resultFilters);

      return resultFilters;
    });
  };

  const clearFilters = () => {
    const date = getDayDateRange();

    setFilters({ date });
    FiltersStorage.setFilters({ date });
  };

  return { filters: filters || {}, setFilters: handleSetFilters, clearFilters };
}
