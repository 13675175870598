import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import useListOrdersQuery from 'core/hooks/queries/useListOrdersQuery';
import LoadableData from 'core/components/LoadableData';
import OrdersList from 'core/components/OrdersList';
import { lastWindowScrollPositionAtom } from '../components/atoms';

const OrdersPicking = () => {
  const storedScrollPosition = useRecoilValue(lastWindowScrollPositionAtom);
  const {
    data: rawOrders,
    loading,
    error,
    refetch,
    hasNextPage,
    loadMore,
    total: rawTotal
  } = useListOrdersQuery({ statuses: ['processing', 'op-testing'], sort: ['defaultCarrier', 'desc'] });

  const total = React.useMemo(() => {
    const filtered = rawOrders?.filter(order => !['processing'].includes(order.status));
    return (rawTotal || 0) - (filtered?.length || 0);
  }, [rawOrders, rawTotal]);

  const orders = React.useMemo(() => {
    return rawOrders?.filter(order => ['processing'].includes(order.status));
  }, [rawOrders]);

  const isErrorOrEmptyData = useMemo(
    () => error || (!loading && !orders?.length),
    [loading, error, orders]
  );

  React.useEffect(() => {
    window.scrollTo(0, storedScrollPosition);
    // console.log('----scroll to---', storedScrollPosition, loading);
  }, [storedScrollPosition, loading]);

  return (
    <LoadableData
      isError={isErrorOrEmptyData}
      isLoading={loading}
      errorMessage="You've no processing orders"
      refetch={refetch}
    >
      <OrdersList orders={orders} urlPathname="picking" loading={loading} hasNextPage={hasNextPage} loadMore={loadMore} total={total} />
    </LoadableData>
  );
};

// const OrdersPicking = () => {
//   const {
//     data: rawOrders,
//     loading,
//     error,
//     refetch,
//     hasNextPage,
//     loadMore,
//     total
//   } = useListOrdersQuery({ statuses: ['processing'] });
//   useListOrdersQuery({ statuses: ['op-testing'] });

//   const orders = React.useMemo(() => {
//     return rawOrders?.filter(order => ['processing'].includes(order.status));
//   }, [rawOrders]);

//   const isErrorOrEmptyData = useMemo(
//     () => error || (!loading && !orders?.length),
//     [loading, error, orders]
//   );

//   return (
//     <LoadableData
//       isError={isErrorOrEmptyData}
//       isLoading={loading}
//       errorMessage="You've no processing orders"
//       refetch={refetch}
//     >
//       <OrdersList orders={orders} urlPathname="picking" loading={loading} hasNextPage={hasNextPage} loadMore={loadMore} total={total} />
//     </LoadableData>
//   );
// };


export default OrdersPicking;
