import React, { useMemo } from 'react';
import useOrdersSort from 'hooks/orders/useOrdersSort';
import useOrderFilters from 'hooks/orders/useOrderFilters';
import FiltersDropdown from 'components/FiltersDropdown';
import OrderControlsList from 'components/OrderControlsList';
import LocationIcon from 'components/icons/LocationIcon';
import FilterSelectList from 'components/FilterSelectList';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

// import useRefreshOrders from 'hooks/orders/useRefreshOrders';
// import useRefreshOrdersShipping from 'hooks/orders/useRefreshOrdersShipping';
import FiltersButton from 'components/FiltersButton/FiltersButton';
import getDayDateRange from 'utils/getDayDateRange';
import useListCarriers from 'core/hooks/queries/useListCarriers';
import DeliveryIcon from 'components/icons/DeliveryIcon';
// import useAppPending from 'hooks/useAppPending';

const FilterContainer = () => {
  // const refreshOrdersSyncing = useRefreshOrdersShipping();
  // const refreshOrders = useRefreshOrders();
  // const {setPending} = useAppPending();
  const { data: carriers } = useListCarriers();

  const { filters, setFilters, clearFilters } = useOrderFilters();
  const { sort, setSort } = useOrdersSort();

  const handleChangeDate = async e => {
    const tomorrowDateRange = getDayDateRange(new Date(Date.now() + 3600 * 1000 * 24));

    setFilters({
      ...filters,
      date: Array.isArray(e) ? e : tomorrowDateRange
    });

    // setPending(true);
    // await refreshOrders();
    // await refreshOrdersSyncing();
    // setPending(false);
  };

  const handleChangeZone = zone => {
    setFilters({ ...filters, zone });
  };

  const handleChangeDefaultCarrier = defaultCarrier => {
    setFilters({ ...filters, defaultCarrier });
  };

  const handleChangeSort = (field, order) => {
    setSort(field, order);
  };

  const handleClearFilters = async () => {
    clearFilters();

    // setPending(true);
    // await refreshOrders();
    // await refreshOrdersSyncing();
    // setPending(false);
  };

  const dateIcon = useMemo(
    () =>
      filters.date && filters.date[0] ? (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17 2H2V17H17V2Z" stroke="#0A6629" strokeWidth="2" />
          <path d="M6 0V4" stroke="#0A6629" strokeWidth="2" />
          <path d="M13 0V4" stroke="#0A6629" strokeWidth="2" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          stroke="black"
          strokeWidth="2"
          className="react-daterange-picker__calendar-button__icon react-daterange-picker__button__icon"
        >
          <rect fill="none" height="15" width="15" x="2" y="2"></rect>
          <line x1="6" x2="6" y1="0" y2="4"></line>
          <line x1="13" x2="13" y1="0" y2="4"></line>
        </svg>
      ),
    [filters]
  );

  const carriersOptions = useMemo(
    () =>
      carriers?.length ? [{ name: 'All', value: null }, ...carriers]?.map(({ name, value }) => ({ value, title: name })) : [],
    [carriers]
  );


  const sortOptions = React.useMemo(() => {
    const options = [
      { name: 'customer', title: 'Customer Name' },
      { name: 'id', title: 'Order ID' },
      { name: 'meals', title: 'Number of meals' },
      { name: 'shipLabelsPrintedAt', title: 'Time of shipping label' },
      { name: 'note', title: 'Waybill' }
    ];

    return options;
  }, [])

  return (
    <div className="filter-container">
      <div className="filter-img-staple"></div>
      <div className="filter-buttons">
        <FiltersDropdown title={dateIcon} hideTriangleIcon>
          <div style={{ paddingTop: 10, paddingBottom: 20 }}>
            <DateRangePicker
              onChange={handleChangeDate}
              value={filters.date}
              locale={'en-US'}
            />
          </div>
        </FiltersDropdown>

        <FiltersDropdown
          title={<DeliveryIcon color={filters?.defaultCarrier ? '#138622' : 'black'} width={28} height={28} />}
          isActive={Boolean(filters?.defaultCarrier)}
        >
          <FilterSelectList
            list={carriersOptions}
            selected={filters?.defaultCarrier || null}
            onChange={handleChangeDefaultCarrier}
          />
        </FiltersDropdown>

        <FiltersDropdown
          title={<LocationIcon color={filters?.zone ? '#138622' : 'black'} />}
          isActive={Boolean(filters?.zone)}
        >
          <FilterSelectList
            list={[
              { value: 'Local', title: 'Local' },
              { value: 'Overnight', title: 'Overnight' },
              { value: 'National', title: 'National' }
            ]}
            selected={filters?.zone}
            onChange={handleChangeZone}
          />
        </FiltersDropdown>

        <FiltersDropdown
          isActive={sort && Object.values(sort)[0]}
          title={<span style={{ fontSize: 12 }}>a/z</span>}
        >
          <OrderControlsList
            list={sortOptions}
            activeItem={sort}
            onChange={handleChangeSort}
          />
        </FiltersDropdown>

        <FiltersButton onClick={handleClearFilters}>Clear</FiltersButton>
      </div>
    </div>
  );
};

export default FilterContainer;
