import styled from 'styled-components/macro';

const ShippedErrorIcon = ({className, width = 32, height = 32}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <rect width="256" height="256" fill="white" />
      <path
        d="M87.0508 69.793L102.873 63.168C104.803 62.3594 106.975 62.3594 108.904 63.168L124.725 69.793V9H87.0508V69.793Z"
        fill="#D90000"
      />
      <path
        d="M188.449 95.3682C196.545 95.3682 204.371 96.5361 211.775 98.7041V28.1846C211.775 17.6064 203.17 9.00098 192.592 9.00098H140.34V81.5283C140.34 84.1416 139.033 86.5811 136.857 88.0283C135.557 88.8936 134.049 89.335 132.531 89.335C131.51 89.335 130.486 89.1357 129.516 88.7295L105.887 78.8369L82.2578 88.7295C79.8496 89.7393 77.0937 89.4756 74.918 88.0283C72.7422 86.5811 71.4355 84.1416 71.4355 81.5283V9.00098H19.1836C8.60547 9.00098 0 17.6064 0 28.1846V201.593C0 212.171 8.60547 220.776 19.1836 220.776H116.836C109.502 208.388 105.283 193.946 105.283 178.536C105.283 132.677 142.592 95.3682 188.449 95.3682Z"
        fill="black"
      />
      <path
        d="M188.552 110.98C151.303 110.98 121 141.287 121 178.532C121 215.781 151.303 246.084 188.552 246.084C225.797 246.084 256.1 215.781 256.1 178.532C256.1 141.287 225.797 110.98 188.552 110.98Z"
        fill="white"
      />
      <path
        d="M189 112C151.969 112 122 141.967 122 179C122 216.031 151.967 246 189 246C226.031 246 256 216.033 256 179C256 141.969 226.033 112 189 112ZM185.785 195.069L170.574 210.283C168.856 211.998 166.574 212.944 164.147 212.944C159.123 212.944 155.056 208.88 155.056 203.853C155.056 201.426 156.001 199.144 157.717 197.426L172.931 182.215C174.707 180.439 174.707 177.561 172.931 175.785L157.717 160.573C156.002 158.855 155.056 156.574 155.056 154.146C155.056 149.122 159.12 145.056 164.147 145.056C166.574 145.056 168.856 146.001 170.574 147.716L185.785 162.931C187.561 164.703 190.439 164.703 192.215 162.931L207.426 147.716C209.144 146.001 211.426 145.056 213.853 145.056C218.877 145.056 222.944 149.119 222.944 154.146C222.944 156.574 221.999 158.855 220.283 160.573L205.069 175.785C203.293 177.56 203.293 180.439 205.069 182.215L220.283 197.426C221.998 199.144 222.944 201.426 222.944 203.853C222.944 208.877 218.88 212.944 213.853 212.944C211.426 212.944 209.144 211.998 207.426 210.283L192.215 195.069C190.439 193.293 187.561 193.296 185.785 195.069Z"
        fill="#D90000"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="256" height="256" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default styled(ShippedErrorIcon)``;
