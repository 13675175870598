const Arrow = ({rotate = false, isActive}) => {
  const color = isActive ? '#138622' : 'black';

  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{transform: `rotate(${rotate ? '180deg' : '0'})`}}
    >
      <path d="M3 11V1.5M1 3L3 1L5 3" stroke={color} />
    </svg>
  );
};

const OrderIcon = ({isDesc, isActive}) => {
  return (
    <div style={{display: 'flex'}}>
      <Arrow isActive={isActive && !isDesc} />
      <Arrow rotate isActive={isActive && isDesc} />
    </div>
  );
};

export default OrderIcon;
