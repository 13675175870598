import styled from 'styled-components/macro';
import useBoolean from 'hooks/helpers/useBoolean';
import TriangleIcon from './component/TriangleIcon';

import styles from './FiltersDropdown.module.css';

const Title = ({title, isActive, ...other}) => (
  <div
    className={styles.header}
    style={{color: isActive ? '#138622' : 'black'}}
    {...other}
  >
    {title}
    <div className={styles.icon}>
      <TriangleIcon color={isActive ? '#138622' : 'black'} />
    </div>
  </div>
);

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const FiltersDropdown = ({children, title, isActive = false}) => {
  const {
    value: isOpenModal,
    setTrue: openModal,
    setFalse: closeModal
  } = useBoolean(false);

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={openModal}>
        <Title title={title} isActive={isActive} />
      </div>

      {isOpenModal && (
        <div className={styles.modal}>
          <Overlay onClick={closeModal} />
          <div className={styles.modalContainer}>
            <Title title={title} onClick={closeModal} />

            <div className={styles.modalContent}>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersDropdown;
