const {gql} = require('@apollo/client');

const PRINTER_JOB_CREATE = gql`
  mutation Printer_printJobPPCreate(
    $content: String!
    $template: String!
    $width: String!
    $height: String!
    $type: String!
    $title: String!
    $pageRanges: String!
    $schema: String!
    $orderId: String!
    $customerName: String!
    $printTemplate: String!
  ) {
    printJobPPCreate(
      jobPP: {
        content: $content
        template: $template
        title: $title
        type: $type
        width: $width
        height: $height
        pageRanges: $pageRanges
        schema: $schema
        orderId: $orderId
        customerName: $customerName
        printTemplate: $printTemplate
      }
    ) {
      id
      errors {
        message
      }
    }
  }
`;

export default PRINTER_JOB_CREATE;
