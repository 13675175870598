import React from 'react';
import styled from 'styled-components/macro';
import { gql, useApolloClient } from '@apollo/client'
import { useRecoilValue } from 'recoil'

import ProductsCounter from 'core/components/ProductsCounter';
import Card from 'core/components/Card';
import { queue } from 'core/components/queue'
import { tabIndexAtom } from '../atoms'

import {
  Counter,
  Customer,
  OrderInfo,
  Header,
  ShippingInfo,
  Title,
  Footer,
  Zone,
  ShipBy,
  InfoLeftPart,
  Printed
} from './OrderCard.styles';

const QUERY_WEEK_NUMBER = gql`
  query weekNumber($orderId: ID!) {
    getCustomerOrderWeek(orderId: $orderId)
  }
`

const OrderCard = ({
  className,
  countTotal,
  index,
  children,
  order
}) => {
  const { id, shipping, zone, items: products, shipBy, currentOrderWeek } = order;
  //const { data, loading } = useQuery(QUERY_WEEK_NUMBER, { variables: { orderId: id }, skip: !id })
  const [week, setWeek] = React.useState('...')
  const client = useApolloClient()
  const tabIndex = useRecoilValue(tabIndexAtom)

  React.useEffect(() => {
    const fn = async () => {
      const result = await client.query({ query: QUERY_WEEK_NUMBER, variables: { orderId: id }, fetchPolicy: 'cache-first' })
      const w = result.data?.getCustomerOrderWeek;
      if (w) {
        setWeek(`W${w}*`)
      }
    }
    if (currentOrderWeek) {
      setWeek(`W${currentOrderWeek}`)
    } else if (id) {
      queue.add(() => fn())
    }
  }, [id, client, currentOrderWeek])

  const shipByUS = React.useMemo(() => {
    const arr = shipBy?.split('-').map(Number);
    if (arr?.length === 3) {
      return [arr[1], arr[2], arr[0]].join('/');
    }
    return shipBy;
  }, [shipBy]);

  const labelsPrinted = React.useMemo(() => {
    const printed = products?.filter(p => p?.product?.category?.name === 'meals').map(p => p.printed);
    if (printed.every(Boolean)) {
      return 'M';
    }
    if (printed.some(Boolean)) {
      return 'm';
    }
    return null;
  }, [products]);

  // const week = loading ? '' : `W${data?.getCustomerOrderWeek} `
  const moreInfo = React.useMemo(() => {
    if (tabIndex !== 1) {
      return ''
    }
    let extInfo = ''
    const diet = order?.diets?.filter(d => d.id !== 'no-preference').map(d => d.title).join(', ') || '';
    if (diet) {
      extInfo += `D-${diet}`;
    }
    const coupon = order?.coupons?.map(c => c.code).join(', ') || '';
    if (coupon) {
      extInfo += ` C-${coupon}`;
    }
    return extInfo;
  }, [order, tabIndex])

  const hasWayBill = React.useMemo(() => {
    return !!(order?.consignmentNote?.title || order?.consignmentNote?.title?.content)
  }, [order])

  return (
    <div className={className}>
      <Card disabled={order?.disabled}>
        <Header>
          {/* <Title>Order#{id} <Status>({status})</Status></Title> */}
          <Title>Order#{id} {week} {moreInfo}</Title>
          <Counter>{index && countTotal && `${index}/${countTotal}`}</Counter>
        </Header>
        <OrderInfo>
          <InfoLeftPart>
            <ProductsCounter products={products} />
            <div style={{ display: 'flex' }}>
              <ShipBy>
                {shipByUS}
              </ShipBy>
              {/* <span>SORT: {order.sort}</span> */}
              {
                labelsPrinted && <Printed>{labelsPrinted}</Printed>
              }
              {
                order?.slipsPrintedManually && <Printed>PS</Printed>
              }
              {
                hasWayBill && <Printed>WB</Printed>
              }
            </div>
          </InfoLeftPart>
          <ShippingInfo>
            <Customer>
              {shipping?.firstName} {shipping?.lastName}
            </Customer>
            <Zone>{zone}</Zone>
          </ShippingInfo>
        </OrderInfo>
        {children && <Footer>{children}</Footer>}
      </Card>
    </div>
  )
};

export default styled(OrderCard)`
  cursor: pointer;
  width: 100%;
`;
