import { useQuery } from "@apollo/client";
import {useMemo} from 'react';
import LIST_CARRIERS from "core/graphql/queries/LIST_CARRIERS";

export default function useListCarriers() {
  const {data, ...other} = useQuery(LIST_CARRIERS);

  const list = useMemo(() => data?.listCarriers, [data]);

  return {data: list, ...other};
}