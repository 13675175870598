import styled from 'styled-components/macro';

const PackedIcon = ({className, width = 32, height = 32}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width={256} height={256} fill="white" />
    <path
      d="M247.853 129.055C240.022 120.726 227.104 119.994 218.382 127.355L181.925 156.451C184.419 161.285 186.171 167.514 186.171 175.483C186.171 194.924 175.747 204.024 169.532 207.717C161.735 212.352 154.028 212.588 152.563 212.588H100.551C96.3812 212.588 93.0023 209.207 93.0023 205.037C93.0023 200.869 96.3812 197.488 100.551 197.488H152.563C152.608 197.488 157.266 197.444 161.817 194.738C167.96 191.088 171.073 184.61 171.073 175.483C171.073 166.356 167.96 159.877 161.817 156.227C157.266 153.522 152.608 153.477 152.561 153.475H119.409C117.19 153.475 115.084 152.5 113.649 150.807C110.122 146.645 101.59 139.664 84.0785 139.664C72.4847 139.664 64.7581 142.703 59.7913 145.986V241.08L162.057 242.885C162.309 242.891 162.561 242.893 162.811 242.893C174.825 242.893 186.301 237.828 194.403 228.926L248.186 169.813C253.288 164.209 256.062 156.947 255.999 149.369C255.938 141.791 253.044 134.576 247.853 129.055Z"
      fill="black"
    />
    <path
      d="M44.6916 160.779V145.68H7.54886C3.38087 145.68 0 149.061 0 153.23V233.397C0 237.567 3.38087 240.948 7.54886 240.948H44.6916V160.779Z"
      fill="#0A6629"
    />
    <path
      d="M212.661 14H174.956V59.2892C174.956 63.4572 171.575 66.8381 167.405 66.8381H125.825C121.655 66.8381 118.276 63.4572 118.276 59.2892V14H81.5999C71.3714 14 63.051 22.3203 63.051 32.5489V127.797C68.7913 125.856 75.7366 124.567 84.0784 124.567C104.51 124.567 116.454 132.305 122.676 138.377H152.563C154.028 138.377 161.735 138.612 169.534 143.249C170.426 143.78 171.286 144.354 172.118 144.959L208.821 115.668C215.366 110.213 223.272 107.416 231.21 107.198V32.5489C231.21 22.3203 222.89 14 212.661 14Z"
      fill="black"
    />
    <path d="M133.374 14H159.856V51.7384H133.374V14Z" fill="#0A6629" />
  </svg>
);

export default styled(PackedIcon)``;
