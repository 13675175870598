const LocationIcon = ({color = 'black'}) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 21C14 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 2 16 8 21ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        />
      </mask>
      <path
        d="M8 21L7.03972 22.1523L8 22.9526L8.96028 22.1523L8 21ZM14.5 8C14.5 9.94885 14.0681 11.6789 12.9655 13.5072C11.8388 15.3754 9.98145 17.3962 7.03972 19.8477L8.96028 22.1523C12.0185 19.6038 14.1612 17.3337 15.5345 15.0565C16.9319 12.7393 17.5 10.4694 17.5 8H14.5ZM8 1.5C11.5899 1.5 14.5 4.41015 14.5 8H17.5C17.5 2.75329 13.2467 -1.5 8 -1.5V1.5ZM1.5 8C1.5 4.41015 4.41015 1.5 8 1.5V-1.5C2.75329 -1.5 -1.5 2.75329 -1.5 8H1.5ZM8.96028 19.8477C6.01855 17.3962 4.16115 15.3754 3.03449 13.5072C1.93192 11.6789 1.5 9.94885 1.5 8H-1.5C-1.5 10.4694 -0.931917 12.7393 0.465506 15.0565C1.83885 17.3337 3.98145 19.6038 7.03972 22.1523L8.96028 19.8477ZM8.5 8C8.5 8.27614 8.27614 8.5 8 8.5V11.5C9.933 11.5 11.5 9.933 11.5 8H8.5ZM8 7.5C8.27614 7.5 8.5 7.72386 8.5 8H11.5C11.5 6.067 9.933 4.5 8 4.5V7.5ZM7.5 8C7.5 7.72386 7.72386 7.5 8 7.5V4.5C6.067 4.5 4.5 6.067 4.5 8H7.5ZM8 8.5C7.72386 8.5 7.5 8.27614 7.5 8H4.5C4.5 9.933 6.067 11.5 8 11.5V8.5Z"
        fill={color}
        mask="url(#path-1-inside-1)"
      />
    </svg>
  );
};

export default LocationIcon;
