import styled from 'styled-components/macro';
import OrderCard from 'core/components/OrderCard';

const DefaultOrderListItem = ({className, ...orderCardProps}) => (
  <div className={className}>
    <OrderCard {...orderCardProps} />
  </div>
);

export default styled(DefaultOrderListItem)``;
