import easyPostCarriers from 'core/lib/easyPostCarriers';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import PackedIcon from '../icons/PackedIcon';
import ShippedErrorIcon from '../icons/ShippedErrorIcon';
import ShippedIcon from '../icons/ShippedIcon';
import ShippingIcon from '../icons/ShippingIcon';
import ButtonPrintOrderLabels from './ButtonPrintOrderLabels';
import ButtonReship from './ButtonReship';

const Body = styled.div`
  padding: 20px 20px 10px 20px;
  display: flex;
  gap: 20px;
`;

const BodyLeftPart = styled.div``;

const BodyRightPart = styled.div``;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

const Customer = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #444;
  margin: 0;
  margin-top: 5px;
`;

const Status = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #444;
  margin: 0;
  margin-top: 5px;
`;

const CarrierColors = {
  EasyPost: '#30409e',
  UPS: '#30409e',
  USPS: '#30409e',
  Route4me: '#fe8f14'
};

const Carrier = styled.div`
  background: ${({ type }) => CarrierColors[type]};
  color: #fff;
  padding: 5px 0;
  border-radius: 0 0 15px 15px;
  text-align: center;
`;

const iconsMap = {
  packed: PackedIcon,
  shipping: ShippingIcon,
  shipped: ShippedIcon,
  hold: ShippedErrorIcon
};

const Icon = ({ status, className }) => {
  const IconComponent = iconsMap[status];

  return (
    <div className={className}>
      {IconComponent && <IconComponent width={20} height={28} />}
    </div>
  );
};

const StyledIcon = styled(Icon)`
  margin-top: 8px;
`;


const shippedStatuses = [
  'shipped',
  'delivered'
];

const isShippedOrder = status => shippedStatuses.includes(status);

const ShippingOrderListItem = ({ className, order }) => {
  const { id, shipping, status, carrier } = order;

  const isRoute4me = useMemo(() => carrier === 'Route4me', [carrier]);
  const isShippedEasyPost = useMemo(
    () => easyPostCarriers.includes(carrier) && isShippedOrder(status),
    [carrier, status]
  );

  return (
    <div className={className}>
      <Body>
        <BodyLeftPart>
          <StyledIcon status={status} />
        </BodyLeftPart>
        <BodyRightPart>
          <Title>{id}</Title>
          <Status>Status: {status}</Status>
          <Customer>
            Customer: {shipping.firstName} {shipping.lastName}
          </Customer>
        </BodyRightPart>
      </Body>
      <Carrier type={carrier}>{carrier}</Carrier>
      {(['hold', 'pending'].includes(status)) && <ButtonReship order={order} />}
      {isRoute4me && <ButtonPrintOrderLabels order={order} />}
      {isShippedEasyPost && <ButtonPrintOrderLabels order={order} />}
    </div>
  );
};

export default styled(ShippingOrderListItem)`
  cursor: pointer;
  width: 100%;
  -webkit-box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  border-radius: 15px;
  background-color: #fff;
  position: relative;
`;
