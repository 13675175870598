
import styled from 'styled-components/macro';


const Budge = styled.div`
    background: #07572a;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
`;

const LoadingMore = () => (
    <Budge>
        Loading...
    </Budge>
  );

export default styled(LoadingMore)`
`;
