const getDayDateRange = (start = new Date()) => {
  start.setHours(0, 0, 0, 0);

  const end = new Date(start);
  end.setHours(23, 59, 59, 999);

  return [start.toISOString(), end.toISOString()];
};

export default getDayDateRange;
