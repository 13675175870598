import useOrderFilters from './useOrderFilters';

export default function useOrdersSort() {
  const {filters, setFilters} = useOrderFilters();
  const {sort} = filters || {};

  const setSort = (field, order) => {
    setFilters({...filters, sort: {[field]: order}});
  };

  const clearSort = () => {
    setFilters({...filters, sort: {}});
  };

  return {sort, setSort, clearSort};
}
