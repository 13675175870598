const settingsKey = 'lifechef-settings';

export default class SettingsStorage {
  static getSettings() {
    try {
      return JSON.parse(localStorage.getItem(settingsKey));
    } catch {
      return {};
    }
  }

  static setSettings(params) {
    localStorage.setItem(settingsKey, JSON.stringify(params));
  }

  static get printingMode() {
    const settings = SettingsStorage.getSettings();
    return settings ? settings.printingMode : null;
  }

  static get printingSchema() {
    const settings = SettingsStorage.getSettings();
    return settings ? settings.printingSchema : 'default';
  }
}
