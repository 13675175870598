import {useContext} from 'react';
import {AppStoreContext} from 'providers/AppStoreProvider';

/**
 * @typedef MealComponent
 * @property {Number} id
 * @property {String} name
 * @property {String} sku
 * @property {String} storeType
 */

/**
 * @typedef Meal
 * @type {Object}
 * @property {Number} id
 * @property {String} name
 * @property {Number} qty
 * @property {String} sky
 * @property {String} storeType
 * @property {Number} _id
 * @property {Array<{id: Number, isPrinted: Boolean, status: String, component: MealComponent}>} components
 */

/**
 * @todo add properties
 * @typedef Vitamin
 * @type {Object}
 */

/**
 * @typedef Zone
 * @type {"Local" | "Overnight" | "National"}
 */

/**
 * @todo add properties
 * @typedef ShippedOrder
 * @type {Object}
 */

/**
 * @todo add properties
 * @typedef ScannedComponents
 * @type {Object}
 */

/**
 * @typedef Filters
 * @property {[String, String]} date
 * @property {Zone} zone
 * @property {{[key: string]: "asc" | "desc"}} sort
 */

/**
 * @typedef Store
 * @type {Object}
 * @property {ShippedOrder[]} ordersShipping
 * @property {ScannedComponents} scannedComponents
 * @property {Filters} filters
 * @property {Boolean} pending
 *
 * @property {(filters: Filters) => void} setFilters
 * @property {(state: any) => void} setOrdersShipping
 * @property {(state: any) => void} setScannedComponents
 * @property {(state: Boolean) => void} setPending
 */

/**
 * @typedef StoreActions
 */

/**
 * @returns {Store}
 */
export default function useAppStore() {
  const store = useContext(AppStoreContext);

  return store;
}
