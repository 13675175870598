const assetsUrl = 'https://stage-media.s3.us-east-2.amazonaws.com/print/';

export default `
    @font-face {
      font-display: swap;
      font-style: normal;
      font-weight: 300;
      font-family: Sora;
      src: url(${assetsUrl}fonts/sora-light.woff2) format("woff2"),
        url(${assetsUrl}fonts/sora-light.woff) format("woff");
    }
    @font-face {
      font-display: swap;
      font-style: normal;
      font-weight: 400;
      font-family: Sora;
      src: url(${assetsUrl}fonts/sora-regular.woff2) format("woff2"),
        url(${assetsUrl}fonts/sora-regular.woff) format("woff");
    }
    @font-face {
      font-display: swap;
      font-style: normal;
      font-weight: 500;
      font-family: Sora;
      src: url(${assetsUrl}fonts/sora-medium.woff2) format("woff2"),
        url(${assetsUrl}fonts/sora-medium.woff) format("woff");
    }
    @font-face {
      font-display: swap;
      font-style: normal;
      font-weight: 600;
      font-family: Sora;
      src: url(${assetsUrl}fonts/sora-semi-bold.woff2) format("woff2"),
        url(${assetsUrl}fonts/sora-semi-bold.woff) format("woff");
    }
    @font-face {
      font-display: swap;
      font-style: normal;
      font-weight: 700;
      font-family: Sora;
      src: url(${assetsUrl}fonts/sora-bold.woff2) format("woff2"),
        url(${assetsUrl}fonts/sora-bold.woff) format("woff");
    }
    @font-face {
      font-display: swap;
      font-style: normal;
      font-weight: 800;
      font-family: Sora;
      src: url(${assetsUrl}fonts/sora-extra-bold.woff2) format("woff2"),
        url(${assetsUrl}fonts/sora-extra-bold.woff) format("woff");
    }
`;
