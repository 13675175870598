import { useMutation } from '@apollo/client';
import useNotification from '../useNotification';

export default function useCoreMutation(mutation) {
  const [request, mutationResult] = useMutation(mutation);
  const notification = useNotification();

  const mutate = async (variables, other = {}) => {
    try {
      const res = await request({ variables, ...other });
      return res;
    } catch (error) {
      console.log('ERROR [Mutation Error]: ', error);
      notification.error(`ERROR [Mutation Error]: ${error.message}`, {});
    }
  };

  return [mutate, mutationResult];
}
