import styled from 'styled-components/macro';

const ShippedIcon = ({className, width = 32, height = 32}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <rect width="256" height="256" fill="white" />
      <path
        d="M87.0508 69.793L102.873 63.168C104.803 62.3594 106.975 62.3594 108.904 63.168L124.725 69.793V9H87.0508V69.793Z"
        fill="#0A6629"
      />
      <path
        d="M188.449 95.3682C196.545 95.3682 204.371 96.5361 211.775 98.7041V28.1846C211.775 17.6064 203.17 9.00098 192.592 9.00098H140.34V81.5283C140.34 84.1416 139.033 86.5811 136.857 88.0283C135.557 88.8936 134.049 89.335 132.531 89.335C131.51 89.335 130.486 89.1357 129.516 88.7295L105.887 78.8369L82.2578 88.7295C79.8496 89.7393 77.0937 89.4756 74.918 88.0283C72.7422 86.5811 71.4355 84.1416 71.4355 81.5283V9.00098H19.1836C8.60547 9.00098 0 17.6064 0 28.1846V201.593C0 212.171 8.60547 220.776 19.1836 220.776H116.836C109.502 208.388 105.283 193.946 105.283 178.536C105.283 132.677 142.592 95.3682 188.449 95.3682Z"
        fill="black"
      />
      <path
        d="M188.552 110.98C151.303 110.98 121 141.287 121 178.532C121 215.781 151.303 246.084 188.552 246.084C225.797 246.084 256.1 215.781 256.1 178.532C256.1 141.287 225.797 110.98 188.552 110.98ZM225.952 172.691L185.129 213.514C182.558 216.085 179.077 217.521 175.457 217.521C175.239 217.521 175.018 217.518 174.793 217.504C170.941 217.317 167.346 215.514 164.893 212.535L147.434 191.315C142.635 185.481 143.472 176.861 149.307 172.061C155.141 167.258 163.761 168.095 168.561 173.93L176.444 183.508L206.608 153.347C211.951 148.004 220.613 148.004 225.952 153.347C231.299 158.686 231.299 167.348 225.952 172.691Z"
        fill="#0A6629"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="256" height="256" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default styled(ShippedIcon)``;
