import classnames from 'classnames';
import styled from 'styled-components/macro';
import styles from './FilterSelectList.module.css';

const Separator = () => <div className={styles.separator} />;

const Radio = styled.div.attrs({})`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  background: ${props => props.checked ? '#000' : 'transparent'};
`;

const RadioCheck = styled.div.attrs({})`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
`;

const RadioButton = props => (
  <Radio {...props}>
    <RadioCheck {...props} />
  </Radio>
);

const ListItem = ({data, isActive = false, onSelect}) => {
  const handleClick = () => onSelect(data.value);

  return (
    <div
      className={classnames({[styles.item]: true, [styles.isActive]: isActive})}
      onClick={handleClick}
    >
      {data.name || data.title}
      <Separator />

      <RadioButton checked={isActive} />
    </div>
  );
};

const FilterSelectList = ({list = [], selected, onChange}) => {
  const handleSelect = value => {
    onChange(selected === value ? null : value);
  };

  return (
    <div className={styles.list}>
      {list?.map(item => (
        <ListItem
          key={item.value}
          data={item}
          isActive={selected === item.value}
          onSelect={handleSelect}
        />
      ))}
    </div>
  );
};

export default FilterSelectList;
