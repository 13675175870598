import styled from 'styled-components/macro';

const ShippingIcon = ({className, width = 32, height = 32}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <rect width="256" height="256" fill="white" />
      <path
        d="M188.449 110.984C151.201 110.984 120.898 141.287 120.898 178.533C120.898 215.781 151.201 246.084 188.449 246.084C225.697 246.084 256 215.781 256 178.533C256 141.285 225.697 110.984 188.449 110.984ZM228.879 189.863H188.928C184.617 189.863 177.121 182.367 177.121 178.055V147.105C177.121 142.793 183.617 138.297 187.928 138.297C192.24 138.297 198.736 142.793 198.736 147.105V168.246H228.879C233.189 168.246 237.686 174.742 237.686 179.055C237.686 183.367 233.191 189.863 228.879 189.863Z"
        fill="#E6B05F"
      />
      <path
        d="M87.0508 69.793L102.873 63.168C104.803 62.3594 106.975 62.3594 108.904 63.168L124.725 69.793V9H87.0508V69.793Z"
        fill="#E5B05F"
      />
      <path
        d="M188.449 95.3682C196.545 95.3682 204.371 96.5361 211.775 98.7041V28.1846C211.775 17.6064 203.17 9.00098 192.592 9.00098H140.34V81.5283C140.34 84.1416 139.033 86.5811 136.857 88.0283C135.557 88.8936 134.049 89.335 132.531 89.335C131.51 89.335 130.486 89.1357 129.516 88.7295L105.887 78.8369L82.2578 88.7295C79.8496 89.7393 77.0938 89.4756 74.918 88.0283C72.7422 86.5811 71.4355 84.1416 71.4355 81.5283V9.00098H19.1836C8.60547 9.00098 0 17.6064 0 28.1846V201.593C0 212.171 8.60547 220.776 19.1836 220.776H116.836C109.502 208.388 105.283 193.946 105.283 178.536C105.283 132.677 142.592 95.3682 188.449 95.3682Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="256" height="256" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default styled(ShippingIcon)``;
