import React, { useMemo } from 'react';
import useListOrdersQuery from 'core/hooks/queries/useListOrdersQuery';
import LoadableData from 'core/components/LoadableData';
import OrdersList from 'core/components/OrdersList';

const OrdersShipped = () => {
  const { data: orders, refetch, loading, error, hasNextPage, loadMore, total } =
    useListOrdersQuery({ statuses: ['shipped', 'delivered'], sort: ['carrier', 'asc'] });

  const isErrorOrEmptyData = useMemo(
    () => error || (!loading && !orders?.length),
    [loading, error, orders]
  );

  return (
    <LoadableData
      isError={isErrorOrEmptyData}
      isLoading={loading}
      errorMessage="You've no picked orders"
      refetch={refetch}
    >
      <OrdersList orders={orders} urlPathname="info" type="shipping" loading={loading} hasNextPage={hasNextPage} loadMore={loadMore} total={total} sortKey="carrier" />
    </LoadableData>
  );
};

export default OrdersShipped;
