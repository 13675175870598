import styled from 'styled-components/macro';
import Button from './Button';
import PrintIcon from './icons/PrintIcon';

const ListItemPrintButton = ({ className, onClick, loading }) => {
  return (
    <div className={className}>
      <Button loading={true} disabled={true} onClick={onClick} onClickDelay={7}>
        {loading ? '...' : <PrintIcon width={26} height={26} />}
      </Button>
    </div>
  );
};

export default styled(ListItemPrintButton)`
  ${Button} {
    position: absolute;
    width: 50px;
    padding: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;
