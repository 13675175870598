import classnames from 'classnames';
import {useMemo} from 'react';
import OrderIcon from './components/OrderIcon';

import styles from './OrderControlsList.module.css';

const ListItem = ({name, isActive, children, onSelect}) => {
  const handleClick = () => {
    onSelect(name);
  };

  return (
    <div
      className={classnames({[styles.listItem]: true, [styles.isActive]: isActive})}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

const Separator = () => <div className={styles.separator} />;

const toggleOrder = order => {
  if (!order) {
    return 'asc';
  }

  if (order === 'asc') {
    return 'desc';
  }

  if (order === 'desc') {
    return null;
  }
};

const OrderControlsList = ({list, activeItem, onChange}) => {
  const [activeName, activeOrder] = useMemo(
    () => (activeItem ? Object.entries(activeItem)[0] : []),
    [activeItem]
  );

  const handleChange = name => {
    const order = toggleOrder(activeName === name ? activeOrder : null);
    onChange(name, order);
  };

  return (
    <div className={styles.list}>
      {list?.map(item => (
        <ListItem
          key={item.name}
          name={item.name}
          isActive={item.name === activeName && activeOrder}
          onSelect={handleChange}
        >
          {item.title} <Separator />{' '}
          <OrderIcon
            isActive={item.name === activeName && activeOrder}
            isDesc={activeOrder === 'desc'}
          />
        </ListItem>
      ))}
    </div>
  );
};

export default OrderControlsList;
