import React from 'react';
import SettingsStorage from 'services/SettingsStorage';

export default function useSettings(
  initState = {
    components: false,
    staples: false,
    meals: false,
    meals2: true,
    mealsVersion: 'meals',
    qa: false
  }
) {
  const [settingsState, setSettingsState] = React.useState();

  React.useEffect(() => {
    const settings = SettingsStorage.getSettings();

    if (settings === null) {
      SettingsStorage.setSettings(initState);
      setSettingsState(initState);
    } else {
      setSettingsState(settings);
    }
  }, []); // eslint-disable-line

  function setMode(params) {
    SettingsStorage.setSettings(params);
    setSettingsState(params);
  }

  return [settingsState, setMode];
}
