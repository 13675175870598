import categoriesMap from 'core/lib/categoriesMap';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import BoxIcon from './icons/BoxIcon';
import MealsIcon from './icons/MealsIcon';
import PillsIcon from './icons/PillsIcon';
import BreakfastIcon from './icons/BreakfastIcon';

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Icon = styled.div``;

const Count = styled.div`
  font-size: 19px;
`;

const getCount = products =>
  products?.reduce(
    (acc, current) => {
      const currentCount = acc[current?.product?.category?.name]
        ? acc[current?.product?.category?.name]
        : 0;

      acc[current?.product?.category?.name] = currentCount + 1;

      return acc;
    },
    {
      [categoriesMap.meal]: 0,
      [categoriesMap.breakfast]: 0,
      [categoriesMap.vitamin]: 0,
      [categoriesMap.grocery]: 0,
      [categoriesMap.vitamin]: 0
    }
  );

const ProductsCounter = ({ className, products }) => {
  const counter = useMemo(() => getCount(products), [products]) || {};

  return (
    <div className={className}>
      <Item>
        <Icon>
          <MealsIcon height={20} width={20} />
        </Icon>
        <Count>{counter[categoriesMap?.meal]}</Count>
      </Item>
      <Item>
        <Icon>
          <BreakfastIcon style={{ width: 24, height: 24 }} />
        </Icon>
        <Count>{counter[categoriesMap?.breakfast]}</Count>
      </Item>
      <Item>
        <Icon>
          <BoxIcon height={20} width={20} />
        </Icon>
        <Count>{counter[categoriesMap?.grocery]}</Count>
      </Item>
      <Item>
        <Icon>
          <PillsIcon height={20} width={20} />
        </Icon>
        <Count>{counter[categoriesMap?.vitamin]}</Count>
      </Item>
    </div>
  );
};

export default styled(ProductsCounter)`
  display: flex;
  min-width: 142px;
  gap: 12px;
`;
