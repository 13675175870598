import React from 'react';
import useListOrdersQuery from 'core/hooks/queries/useListOrdersQuery';
import LoadableData from 'core/components/LoadableData';
import OrdersList from 'core/components/OrdersList';
import { useMemo } from 'react';

const OrdersPacking = () => {
  const { data: orders, refetch, loading, error, hasNextPage, loadMore, total } = useListOrdersQuery({ status: 'picked' });

  const isErrorOrEmptyData = useMemo(
    () => error || (!loading && !orders?.length),
    [loading, error, orders]
  );

  return (
    <LoadableData
      isError={isErrorOrEmptyData}
      isLoading={loading}
      errorMessage="You've no picked orders"
      refetch={refetch}
    >
      <OrdersList orders={orders} urlPathname="packing" loading={loading} hasNextPage={hasNextPage} loadMore={loadMore} total={total} />
    </LoadableData>
  );
};

export default OrdersPacking;
