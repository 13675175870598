import React from 'react';

import { useRouter } from 'next/router';
import AuthService from '../services/auth';

export const LAST_PATH_KEY = 'pnp::last::path';

const login = '/login?redirected=true'; // Define your login route address.
const WithPrivateRoute = WrappedComponent => {
  const WithPrivateRouteComponent = props => {
    const router = useRouter();
    const [authenticated, setAuthenticated] = React.useState();

    React.useEffect(() => {
      AuthService.getCurrentUser()
        .then(user => {
          setAuthenticated(!!user);
        })
        .catch(() => setAuthenticated(false));
    }, []);

    React.useEffect(() => {
      if (authenticated === false) {
        sessionStorage.setItem(LAST_PATH_KEY, router.asPath);
        router.push(login);
      }
    }, [authenticated, router]);

    if (authenticated === undefined) {
      return <div>...</div>;
    }

    return <WrappedComponent {...props} />;
  };

  return WithPrivateRouteComponent;
};

export default WithPrivateRoute;
