import styled from 'styled-components/macro';

const PrintIcon = ({className, width = 32, height = 32}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.5834 7.58463H19.5001V2.16797H6.50008V7.58463H5.41675C3.62166 7.58463 2.16675 9.03955 2.16675 10.8346V18.418C2.16675 19.614 3.13741 20.5846 4.33341 20.5846H6.50008V23.8346H19.5001V20.5846H21.6667C22.8627 20.5846 23.8334 19.614 23.8334 18.418V10.8346C23.8334 9.03955 22.3785 7.58463 20.5834 7.58463ZM8.66675 4.33464H17.3334V7.58463H8.66675V4.33464ZM8.66675 21.668V17.3346H17.3334V21.668H8.66675ZM20.5834 13.0013H16.2501V10.8346H20.5834V13.0013Z"
      fill="white"
    />
  </svg>
);

export default styled(PrintIcon)``;
