import getDayDateRange from 'utils/getDayDateRange';

const key = 'lifechef-filters';

export default class FiltersStorage {
  static getFilters() {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch {
      return {
        date: getDayDateRange()
      };
    }
  }

  static setFilters(params) {
    sessionStorage.setItem(key, JSON.stringify(params));
  }
}
